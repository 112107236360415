

interface CheckPlanType  {
  id: string;
  bu: string;
  checkingRoundId: string;
  checkingRound: string;
  batchCount: string;
  programCount: string;
  lastOperationTime: string;
  operationUser: string;
  statusText: string;
}
interface CheckingRoundType {
  key: string;
  value: string;
}
import { message ,Modal } from "ant-design-vue";
import {  ref, onMounted ,reactive, toRefs,defineComponent  } from "vue";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { getUser } from "@/API/claim/delaerClaim/delaer";
import { checkingRoundReject,checkingRoundArrpove,getCheckingRoundDropListNoSave,getCheckingRoundApproveTable ,getCheckingRoundStatus ,downloadDetail} from "@/API/checking/checkingPlan";
export default defineComponent({
    components:{
    },
    setup() {
      //查询框数据
      const searchData = reactive<any>({
        checkingRoundVal: null, 
        BUVal:null, 
        statusVal:null
      });

            const buArray = ref<any[]>([]);

      //CheckingRound的数据，从后端获取
      const checkingRoundArray = ref<CheckingRoundType[]>([]);
      //CheckingRound的status数据，从后端获取
      const checkingRoundStatusArray = ref<CheckingRoundType[]>([]);
      //分页
      const pagination = reactive({
        total:0,
        currentPage:1,
        pageSize:20
      })

      //表格

      const dataSource = ref<CheckPlanType[]>([]);
       const columns =[
          {
            title: 'Checking Round',
            dataIndex: 'checkingRound',
            key: 'checkingRound',
            width:120,
          },
          {
            title: 'BU',
            dataIndex: 'bu',
            key: 'bu',
            width:80,
          },
          {
            title: 'Program Count',
            dataIndex: 'programCount',
            key: 'programCount',
            width:140,
          },
          {
            title: 'Batch Count',
            dataIndex: 'batchCount',
            key: 'batchCount',
            width:120,
          },
          {
            title: 'Last Operation Time',
            dataIndex: 'lastOperationTime',
            key: 'lastOperationTime',
            width:170,
          },
          {
            title: 'Operation User',
            dataIndex: 'operationUser',
            key: 'operationUser',
            width:120,
          },
          {
            title: 'Status',
            dataIndex: 'statusText',
            key: 'statusText',
            width:120,
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            slots: { customRender: 'operation' },
            width:120,
          },
        ]
      //表格高度
      const tableHeight = window.innerHeight - 430;
      //表格类型
      type Key = ColumnProps['key'];

      const state = reactive<{
        selectedRowKeys: Key[];
        loading: boolean;
      }>({
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
      });
      

      //表格事件
      const onSelectChange = (selectedRowKeys: Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        state.selectedRowKeys = selectedRowKeys;
      };

      //导出表格
      const exportTable = (record: any)=>{
        console.log(record)
        downloadDetail({planId:record.id});
      }

      //删除表格
      const deleteTable = (record: any)=>{
        console.log(record)
      }

      //获取CheckingRound
      const getCheckingRound = ()=>{
        // getCheckingRoundDropList
        getCheckingRoundDropListNoSave().then((res) => {
          console.log(res)
          checkingRoundArray.value = res
        })
      }

      //获取表格status
      const getStatus =  ()=>{

        
        getCheckingRoundStatus({groupCode:'approve_checking_plan_status'}).then((res) => {
          // checkingRoundStatusArray
          for (let i=0;i<res.length;i++){
            checkingRoundStatusArray.value.push({
              key:res[i].itemCode,
              value:res[i].itemNameEn
            })
          }
        })
      }


      
      const searchParams = reactive<any>({
        checkingRoundVal: null, 
        BUVal:null, 
        statusVal:null
      });
                  //重新设置筛选信息
      const resetTableData = ()=>{
        searchData.checkingRoundVal = null;
        searchData.BUVal = null;
        searchData.statusVal = null;
        searchParams.checkingRoundVal = null;
        searchParams.BUVal = null;
        searchParams.statusVal = null;
      }

      //获取表格数据
      const getTableData = ()=>{
        
        const params = {
          current:pagination.currentPage-1,
          size:pagination.pageSize,
          bu:searchParams.BUVal,
          checkingRoundId:searchParams.checkingRoundVal,
          statuses:[searchParams.statusVal]
        }

        if ( searchParams.BUVal === null ||  searchParams.BUVal === "" ||  searchParams.BUVal === undefined){
          let str = "";
          for (let i=0;i<buArray.value.length;i++){
            str+= buArray.value[i].nameEn +','
          }

          str = str.substr(0, str.length - 1);
          params.bu = str;
        }

        getCheckingRoundApproveTable(params).then((res) => {
          console.log(res);
          pagination.total = res.totalNum;
          dataSource.value = []
          for (let i=0;i<res.data.length;i++){
            const one = res.data[i];

            dataSource.value.push({
                id: one.id,
                bu: one.bu,
                checkingRoundId: one.checkingRoundId,
                checkingRound: one.checkingRound,
                batchCount: one.batchCount,
                programCount: one.programCount,
                lastOperationTime: one.lastOperationTime,
                operationUser: one.operationUser,
                statusText: one.statusText
            })

          }
                      state.selectedRowKeys = [];
        })
      }


      const searchTable = ()=>{
        pagination.currentPage = 1;
        searchParams.checkingRoundVal = searchData.checkingRoundVal;
        searchParams.BUVal = searchData.BUVal;
        searchParams.statusVal =searchData.statusVal;
        getTableData();
      }
      //更改分页页签
      const pageChange = (page: number)=>{
        console.log('改变页码'+page)
        pagination.currentPage = page;
        state.selectedRowKeys = [];
        getTableData();
      }
      //改变每页大小
      const sizeChange = (page: number,pageSize: number)=>{
        console.log('改变页码'+page)
        console.log('改变页码大小'+pageSize)
        pagination.pageSize = pageSize;
        state.selectedRowKeys = [];
        pagination.currentPage = 1;
        getTableData();
      }
      
      const approveCheckingPlan = ()=>{
        if (state.selectedRowKeys.length === 0){
          message.error('Please select one and click again!')
          return;
        }

        Modal.confirm({
          title: 'Please confirm',
          content: 'Do you confirm to approve current checking plan?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            checkingRoundArrpove(state.selectedRowKeys).then((res) => {
              if (res.code === "0"){
                message.success(res.message),
                getTableData();
              } else {
                message.error(res.message)
              }
            })
          },
          onCancel() {
            console.log('Cancel');
          },
        });

      }

      const rejectCheckingPlan = ()=>{
        if (state.selectedRowKeys.length === 0){
          message.error('Please select one and click again!')
          return;
        }

        Modal.confirm({
          title: 'Please confirm',
          content: 'Do you confirm to reject current checking plan?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            checkingRoundReject(state.selectedRowKeys).then((res) => {
              if (res.code === "0"){
                message.success(res.message),
                getTableData();
              } else {
                message.error(res.message)
              }
            })
          },
          onCancel() {
            console.log('Cancel');
          },
        });


      }

      onMounted(() => {
          // getTableData()
          getCheckingRound();
          getStatus();
          getUser().then((res)=>{
            buArray.value= res.buList.filter((item: any) =>{
              return item.parentId === null
            })
            getTableData()
          })
      })

      return {
        buArray,
        searchTable,
        rejectCheckingPlan,
        approveCheckingPlan,
        resetTableData,
        checkingRoundStatusArray,
        sizeChange,
        pageChange,
        exportTable,
        deleteTable,
        ...toRefs(state),
        onSelectChange,
        tableHeight,
        dataSource,columns,
        ...toRefs(pagination),
        ...toRefs(searchData),
        checkingRoundArray,
        getTableData
      }
    }
})
